import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { VehiclePart } from './vehicle-part-selector.component';
import { DeviceInfo } from '../device.info';

@Component({
  selector: 'fc-part-select-with-dropdown',
  template: `
    <fc-vehicle-part-selector
      [selectedPart]="selectedPart"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="isOpen = !isOpen"
      title="Add tracker"
      [active]="isOpen"
      [error]="error"
    ></fc-vehicle-part-selector>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'transparent'"
      [cdkConnectedOverlayWidth]="selectorWidth"
      (backdropClick)="isOpen = false"
    >
      <fc-vehicle-part-dropdown
        [style.width]="selectorWidth"
        [update]="update"
        (partSelected)="partSelected($event)"
      ></fc-vehicle-part-dropdown>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclePartSelectWithDropdownComponent implements AfterViewInit {
  @Input() update: boolean;
  @Input() error: boolean;
  @Input() disabled: boolean;
  @Input() selectedPart: DeviceInfo;
  @Output() partSelectedEmitter = new EventEmitter<VehiclePart>();
  isOpen: boolean;
  selectorWidth: number;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.selectorWidth = this.elRef.nativeElement.offsetWidth;
  }

  partSelected(part: VehiclePart) {
    this.isOpen = false;
    this.partSelectedEmitter.emit(part);
  }
}
