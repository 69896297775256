import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface VehiclePart {
  name: string;
  image: string;
  value: string;
  disabled?: boolean;
}

@Component({
  selector: 'fc-vehicle-part-selector',
  template: `
    <div
      class="select-part part-selector"
      [ngClass]="{ active, error, disable }"
      *ngIf="!selectedPart"
    >
      <div class="title">{{ title }}</div>
      <div class="action">
        <fc-icon icon="icon-add" color="primary500" />
      </div>
    </div>
    <div class="selected-part part-selector" *ngIf="selectedPart">
      <div class="part-info">
        <img
          class="part-image"
          *ngIf="selectedPart.partImage"
          [src]="selectedPart.partImage"
          alt="part-img"
        />
        <div class="part-text">
          <div class="title">{{ selectedPart?.title }}</div>
          <div class="subtitle">
            {{ selectedPart?.subtitle }}
            <img
              class="additional-image"
              *ngIf="selectedPart.harnessImage"
              [src]="selectedPart.harnessImage"
              alt="harness-img"
            />
          </div>
        </div>
      </div>
      <div *ngIf="selectedPart" class="part-edit">
        <fc-icon icon="icon-create"></fc-icon>
      </div>
    </div>
  `,
  styles: [
    `
      @use 'var' as *;

      .select-part {
        color: $primary-600;
      }

      .part-selector {
        border: 1px solid $grey-100;
        border-radius: 4px;
        padding: 0 16px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover,
        &.active {
          border-color: $primary-600;
        }

        &.error {
          border-color: $error-500;
        }

        &.disable {
          border-color: $grey-200;
          opacity: 0.3;
          pointer-events: none;
        }
      }

      .part-image {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }

      .part-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }

      .subtitle {
        color: $grey-400;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }

      .part-edit,
      .action {
        width: 24px;
        height: 24px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclePartSelectorComponent {
  @Input() error: boolean;
  @Input() disable: boolean;
  @Input() active: boolean;
  @Input() title: string;
  @Input() selectedPart: {
    title: string;
    subtitle?: string;
    partImage?: string;
    harnessImage?: string;
  };
}
