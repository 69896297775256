import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehiclePart } from './vehicle-part-selector.component';

@Component({
  selector: 'fc-vehicle-part-dropdown',
  template: `
    <div class="part-dropdown">
      <div
        (click)="partSelected.emit(part)"
        *ngFor="let part of parts"
        [class.disabled]="part?.disabled"
        class="part-item"
      >
        <img class="part-image" [src]="part.image" alt="part-img" />
        <div class="part-name">{{ part.name }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      @use 'var' as *;

      :host {
        width: 100%;
        margin-top: 8px;
      }

      .part-dropdown {
        background: $white;
        box-shadow: $box-shadow-2xl;
        border-radius: 4px;
        z-index: 1;
      }

      .part-item {
        height: 48px;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 12px;

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }

        &:hover {
          background: $secondary-50;
          cursor: pointer;
        }
      }

      .part-image {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    `,
  ],
})
export class VehiclePartDropdownComponent {
  parts: VehiclePart[] = [
    { value: 'harness', name: 'Harness', image: 'assets/images/harness.png' },
    { value: 'odb', name: 'ODB', image: 'assets/images/odb.png' },
    {
      value: 'no-device',
      name: 'No device',
      image: 'assets/images/no-device.png',
    },
  ];

  @Input('update') set update(state: boolean) {
    this.parts[2].disabled = state;
  }
  @Output() partSelected = new EventEmitter<VehiclePart>();
}
