import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getSchedules, getVehicleStatsValues } from '../../store';
import * as fromVehicles from '../../store/reducers';
import { getVehicleFromRouterId } from '../../../store';
import { Observable } from 'rxjs';
import { MaintenanceSchedule, Vehicle } from '@fc-core';
import { VehicleStats } from '../../service/vehicle-statistics-api.service';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'fc-vehicle-maintenance-tab',
  template: `
    <fc-vehicle-maintenance
      [vehicleStats]="vehicleStats$ | async"
      [scheduleList]="scheduleList$ | async"
      [vehicle]="vehicle$ | async"
    ></fc-vehicle-maintenance>
  `,
})
export class VehicleMaintenanceTabComponent implements OnInit {
  vehicleStats$: Observable<VehicleStats>;
  vehicle$: Observable<Vehicle>;
  scheduleList$: Observable<MaintenanceSchedule[]>;

  constructor(private store: Store<fromVehicles.State>) {}

  ngOnInit(): void {
    this.vehicleStats$ = this.store.pipe(select(getVehicleStatsValues()));
    this.scheduleList$ = this.store.pipe(
      select(getSchedules()),
      map((schedules) =>
        [...schedules].sort((a, b) => a.name.localeCompare(b.name)),
      ),
    );
    this.vehicle$ = this.store.pipe(
      select(getVehicleFromRouterId),
      filter((vehicle) => !!vehicle),
      distinctUntilChanged((a, b) => a.id === b.id),
    );
  }
}
