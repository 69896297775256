import { Component, Self } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleManufactureService } from './vehicle-manifacture.service';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { finalize } from 'rxjs/operators';
import { VehicleManufacture } from '../../../models/vehicle.manufacture';

@Component({
  selector: 'fc-vehicle-manufacture-autocomplete',
  template: `
    <fc-form-field
      [ngClass]="{
        'ng-invalid': ngControl.invalid,
        'mat-form-field-invalid': ngControl.invalid,
        'ng-touched': ngControl.touched,
      }"
    >
      <fc-label>Manufacture</fc-label>
      <input
        data-cy="manufacturer-input"
        type="text"
        placeholder="Select Manufacture"
        fcInput
        name="manufacture"
        autocomplete="false"
        maxlength="20"
        (input)="onSearch()"
        [required]="required"
        [(ngModel)]="value"
        #input
        [matAutocomplete]="manufactureAutocomplete"
      />
      <mat-autocomplete
        (optionSelected)="optionSelected($event)"
        [displayWith]="displayFn"
        #manufactureAutocomplete="matAutocomplete"
      >
        <mat-option *ngFor="let option of list$ | async" [value]="option">
          <div class="option-container">
            <div class="title">{{ option.name }}</div>
            <img
              alt="option-img"
              class="option-img"
              *ngIf="option['vectorLogo']"
              [src]="option['vectorLogo']"
            />
          </div>
        </mat-option>
      </mat-autocomplete>
      <mat-spinner class="loader" *ngIf="loading" diameter="20"></mat-spinner>
      <fc-error class="error" *ngIf="ngControl.invalid">
        {{ error }}
      </fc-error>
    </fc-form-field>
  `,
  styleUrls: [
    '../../../../shared/ui/search-inputs/search-input-base-styles.scss',
  ],
})
export class VehicleManufactureAutocompleteComponent
  implements ControlValueAccessor
{
  list$: Observable<VehicleManufacture[]>;
  loading: boolean;
  value = '';

  constructor(
    private vehicleManufactureService: VehicleManufactureService,
    @Self() public ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
  }

  get required() {
    return this.ngControl.control.validator({} as any)?.required;
  }

  get error() {
    if (this.ngControl?.errors)
      return this.ngControl?.errors['_server']
        ? this.ngControl.errors._server[0]
        : 'Field should not be empty';
  }

  onSearch() {
    this.list$ = this.getList(this.value);
    this.onChange(this.value);
  }

  onChange = (value: any) => this.ngControl.control.updateValueAndValidity();

  onTouched = () => null;

  writeValue(value: string): void {
    this.value = value;
    this.list$ = this.getList(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  displayFn(value: VehicleManufacture): string {
    return value?.name;
  }

  getList(value: string): Observable<VehicleManufacture[]> {
    this.loading = true;
    return this.vehicleManufactureService
      .getVehicleManufacture(value || '')
      .pipe(finalize(() => (this.loading = false)));
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.onChange(event.option.value.slug);
  }
}
