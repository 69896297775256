import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealTimeVideoPopupComponent } from './real-time-video-popup.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EmptyStateModule } from '@fc-shared/ui/empty-state/empty-state.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { VideoSourceSwitcherModule } from '../../../vehicles/components/vehicle-dvr/video-source-switcher/video-source-switcher.module';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@NgModule({
  declarations: [RealTimeVideoPopupComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    EmptyStateModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    VideoSourceSwitcherModule,
    IconButtonComponent,
    IconComponent,
  ],
  exports: [RealTimeVideoPopupComponent],
})
export class RealTimeVideoPopupModule {}
