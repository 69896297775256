import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { MomentService, Vehicle } from '@fc-core';
import { select, Store } from '@ngrx/store';
import { getVehicleFromRouterId } from '../../../store';
import { EngineLightChart } from '../../components';
import {
  clearEngineLightDtc,
  getActiveDtc,
  getActiveDtcLoading,
  getDtcList,
  getDtcListLoading,
  getEngineLightsChartData,
  loadActiveLampDtc,
  loadDtcList,
  loadMilStatus,
} from '../../store';
import { filter, map, tap } from 'rxjs/operators';
import { Moment } from 'moment';
import { Dtc, ShortDtc } from '../../models/dtc';

@Component({
  selector: 'fc-vehicle-engine-light-tab',
  template: `
    <ng-container *ngIf="vehicle$ | async as vehicle">
      <fc-diagnostics-active-lamp
        [dtc]="activeDtc$ | async"
        [loading]="activeDtcLoading$ | async"
      ></fc-diagnostics-active-lamp>

      <fc-diagnostics-chart
        [data]="dtcList$ | async"
        [dateRange]="dateRange"
        [loading]="dtcLoading$ | async"
        (dateRangeChanged)="changeDateRange($event, vehicle.id)"
      ></fc-diagnostics-chart>

      <fc-diagnostics-code-history
        [tableData]="codeHistoryData$ | async"
        [isLoading]="dtcLoading$ | async"
      ></fc-diagnostics-code-history>
    </ng-container>
  `,
  styles: [
    `
      .loading,
      .empty-state {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleEngineLightTabComponent implements OnInit, OnDestroy {
  vehicle$: Observable<Vehicle>;
  dtcList$: Observable<EngineLightChart[]>;
  activeDtc$: Observable<Dtc>;
  dtcLoading$: Observable<boolean>;
  codeHistoryData$: Observable<ShortDtc[]>;
  dateRange: { start: Moment; end: Moment };
  currentVehicleId: number;
  activeDtcLoading$: Observable<boolean>;

  constructor(
    private store: Store,
    private momentService: MomentService,
  ) {}

  ngOnInit(): void {
    this.vehicle$ = this.store.pipe(select(getVehicleFromRouterId)).pipe(
      filter((vehicle) => {
        if (!this.currentVehicleId || this.currentVehicleId !== vehicle?.id) {
          this.currentVehicleId = vehicle.id;
          return !!vehicle;
        }
      }),
      tap((vehicle) => {
        if (vehicle) {
          this.store.dispatch(
            loadDtcList({
              vehicleId: vehicle.id,
              dateRange: {
                start: this.dateRange.start.toDate(),
                end: this.dateRange.end.toDate(),
              },
            }),
          );
          this.store.dispatch(loadMilStatus({ vehicleId: vehicle.id }));
          this.store.dispatch(loadActiveLampDtc({ vehicleId: vehicle.id }));
        }
      }),
    );
    this.dtcList$ = this.store.pipe(select(getEngineLightsChartData())).pipe(
      map((data) => {
        return data.map((item) => {
          return {
            code: item.code,
            durations: item.durations.map((duration) => {
              return {
                lower: this.momentService.moment(duration.lower).toDate(),
                upper: duration.upper
                  ? this.momentService.moment(duration.upper).toDate()
                  : this.momentService.moment().toDate(),
              };
            }),
          };
        });
      }),
    );
    this.activeDtc$ = this.store.pipe(select(getActiveDtc()));
    this.codeHistoryData$ = this.store.pipe(select(getDtcList()));
    this.dtcLoading$ = this.store.pipe(select(getDtcListLoading()));
    this.activeDtcLoading$ = this.store.pipe(select(getActiveDtcLoading()));
    this.dateRange = {
      start: this.momentService.moment().subtract(6, 'day'),
      end: this.momentService.moment().add(1, 'day'),
    };
  }

  ngOnDestroy() {
    this.store.dispatch(clearEngineLightDtc());
  }

  changeDateRange(
    dateRange: { start: Moment; end: Moment },
    vehicleId: number,
  ) {
    this.dateRange = dateRange;
    this.store.dispatch(
      loadDtcList({
        vehicleId: vehicleId,
        dateRange: {
          start: dateRange.start.toDate(),
          end: dateRange.end.toDate(),
        },
      }),
    );
  }
}
