import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Vehicle } from '@fc-core';
import { select, Store } from '@ngrx/store';
import * as fromVehicles from '../../store/reducers';
import { getVehicleFromRouterId } from '../../../store';

@Component({
  selector: 'fc-vehicle-photos-tab',
  template: `
    <fc-vehicle-photos [vehicle]="vehicle$ | async"></fc-vehicle-photos>
  `,
})
export class VehiclePhotosTabComponent implements OnInit {
  vehicle$: Observable<Vehicle>;

  constructor(private store: Store<fromVehicles.State>) {}

  ngOnInit(): void {
    this.vehicle$ = this.store.pipe(select(getVehicleFromRouterId));
  }
}
