import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromVehicles from '../../store/reducers';
import { Observable } from 'rxjs';
import {
  clearAllCameraInfo,
  getCurrentCameraInfo,
  getSchedules,
  getVehiclePhotos,
  getVehicleStatsValues,
  loadCurrentCameraStatus,
} from '../../store';
import { VehicleStats } from '../../service/vehicle-statistics-api.service';
import {
  MaintenanceSchedule,
  Vehicle,
  VehicleGroup,
  VehicleOperator,
  VehicleType,
} from '@fc-core';
import {
  addVehicleToGroup,
  getAllVehicleGroups,
  getUsedVehicleTypeById,
  getVehicleFromRouterId,
  loadUsedVehicleTypes,
  removeVehicleFromGroup,
  selectVehicleByIdWithHarnessAndHardware,
  selectVehicleId,
} from '../../../store';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ActiveDaysService } from '@fc-core/services/active-days.service';
import { MediaModel } from '../../service/media-model';
import {
  getCameraStatusById,
  getCurrentCameraInfoSelector,
} from '../../store/selectors/camera.selectors';
import { loadCurrentDvrSettings } from '@fc-store/actions/dvr-settings.actions';
import { CameraInfo } from '../../models/camera/camera-info';
import { CameraState } from '../../models/camera/camera-state';
import { EventSourceService } from '../../../map/services/eventsource.service';

@Component({
  selector: 'fc-vehicle-detail-page',
  templateUrl: './vehicle-detail-page.component.html',
  styleUrls: ['./vehicle-detail-page.component.scss'],
})
export class VehicleDetailPageComponent implements OnDestroy, AfterContentInit {
  selectedVehicle$: Observable<Vehicle>;
  vehicleStats$: Observable<VehicleStats>;
  vehicleGroups$: Observable<VehicleGroup[]>;
  scheduleList$: Observable<MaintenanceSchedule[]>;
  vehiclePhotos$: Observable<MediaModel[]>;
  assignedOperators$: Observable<VehicleOperator[]>;
  currentCameraInfo$: Observable<CameraInfo>;
  selectedVehicle: Vehicle;
  cameraState$: Observable<CameraState>;
  vehicleType$: Observable<VehicleType>;
  private currentCameraId: number;

  constructor(
    private store: Store<fromVehicles.State>,
    private activeDaysService: ActiveDaysService,
    private eventSource: EventSourceService,
  ) {}

  ngAfterContentInit() {
    this.currentCameraInfo$ = this.store.pipe(
      select(getCurrentCameraInfoSelector),
    );
    this.scheduleList$ = this.store.pipe(select(getSchedules()));
    this.vehicleGroups$ = this.store.pipe(select(getAllVehicleGroups));
    this.vehiclePhotos$ = this.store.pipe(select(getVehiclePhotos()));
    this.selectedVehicle$ = this.store.pipe(
      select(getVehicleFromRouterId),
      filter((vehicle) => !!vehicle),
      distinctUntilChanged((prev, curr) => {
        if (prev.type !== curr.type) {
          this.store.dispatch(loadUsedVehicleTypes());
        }
        return (
          prev.id === curr.id &&
          prev.type === curr.type &&
          prev.camera === curr.camera
        );
      }),
      switchMap((vehicle) => {
        this.getVehicleType(vehicle);
        this.getAllCameraInformation(vehicle);

        if (
          !this.selectedVehicle ||
          (this.selectedVehicle && this.selectedVehicle.id !== vehicle.id)
        ) {
          this.selectedVehicle = vehicle;
          this.activeDaysService.vehicleId = vehicle.id;
        }
        return this.store.pipe(
          select(selectVehicleByIdWithHarnessAndHardware(vehicle.id)),
        );
      }),
    );
    this.assignedOperators$ = this.selectedVehicle$.pipe(
      map((vehicle) => vehicle?.operators),
    );
    this.vehicleStats$ = this.store.pipe(select(getVehicleStatsValues()));
  }

  ngOnDestroy(): void {
    this.store.dispatch(selectVehicleId({ vehicleId: null }));
    this.eventSource.closeCameraEventSource();
  }

  onVehicleGroupToggle(payload: {
    action: string;
    vehicleId: number;
    groupId: number;
  }) {
    payload.action === 'add'
      ? this.store.dispatch(
          addVehicleToGroup({
            groupId: payload.groupId,
            vehicleId: payload.vehicleId,
          }),
        )
      : this.store.dispatch(
          removeVehicleFromGroup({
            groupId: payload.groupId,
            vehicleId: payload.vehicleId,
          }),
        );
  }

  private getAllCameraInformation(vehicle: Vehicle): void {
    if (!vehicle.camera) this.clearAllCameraInfo();
    if (vehicle.camera && this.currentCameraId !== vehicle.camera) {
      const cameraId = vehicle.camera;
      this.updateCurrentCamera(cameraId);
      this.store.dispatch(loadCurrentCameraStatus({ cameraId }));
      this.store.dispatch(getCurrentCameraInfo({ cameraId }));
      this.store.dispatch(loadCurrentDvrSettings({ cameraId }));
      this.cameraState$ = this.store.pipe(
        select(getCameraStatusById(this.selectedVehicle?.camera)),
      );
    }
  }

  private clearAllCameraInfo(): void {
    this.currentCameraId = null;
    this.eventSource.closeCameraEventSource();
    this.store.dispatch(clearAllCameraInfo());
  }

  private updateCurrentCamera(cameraId: number): void {
    this.currentCameraId = cameraId;
    this.eventSource.closeCameraEventSource();
    this.eventSource.connectToCameraEventSource(cameraId);
  }

  getVehicleType(vehicle: Vehicle): void {
    this.vehicleType$ = this.store.pipe(
      select(getUsedVehicleTypeById(vehicle?.type)),
    );
  }
}
