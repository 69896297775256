import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty',
})
export class IsEmptyPipe<T> implements PipeTransform {
  transform(array: T[]): boolean {
    if (!array) return null;
    return array?.length === 0;
  }
}
