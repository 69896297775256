import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  Vehicle,
  VehicleGroup,
  VehicleType,
  VehicleTypeDetail,
} from '@fc-core';
import { VehicleStats } from '../../service/vehicle-statistics-api.service';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  getAllVehicleGroups,
  getUsedVehicleTypeById,
  getUsedVehicleTypes,
  getVehicleFromRouterId,
  selectVehicleByIdWithHarnessAndHardware,
} from '../../../store';
import { distinctUntilKeyChanged, filter, switchMap } from 'rxjs/operators';
import { getVehicleStatsValues, getVehicleTypeDetail } from '../../store';
import { CameraInfo } from '../../models/camera/camera-info';
import { getCurrentCameraInfoSelector } from '../../store/selectors/camera.selectors';

@Component({
  selector: 'fc-vehicle-detail-tab',
  template: `
    <div class="detail-tab">
      <fc-vehicle-item-detail
        [vehicle]="vehicle$ | async"
        [currentVehicleType]="currentVehicleType$ | async"
        [vehicleStats]="vehicleStats$ | async"
      ></fc-vehicle-item-detail>
      <fc-hardware-item-detail
        *ngIf="(vehicle$ | async)?.nonTracker === false"
        [vehicle]="vehicle$ | async"
        [cameraInfo]="cameraInfo$ | async"
      ></fc-hardware-item-detail>
      <fc-dimensions-item-detail
        [vehicle]="vehicle$ | async"
        [vehicleTypeDetail]="vehicleTypeDetail$ | async"
        [vehicleTypes]="vehicleTypes$ | async"
      ></fc-dimensions-item-detail>
    </div>
  `,
  styles: [
    `
      .detail-tab {
        padding: 24px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleDetailTabComponent implements OnInit {
  vehicle$: Observable<Vehicle>;
  vehicleGroups$: Observable<VehicleGroup[]>;
  vehicleStats$: Observable<VehicleStats>;
  vehicleTypeDetail$: Observable<VehicleTypeDetail>;
  vehicleTypes$: Observable<VehicleType[]>;
  currentVehicleType$: Observable<VehicleType>;
  cameraInfo$: Observable<CameraInfo>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.vehicle$ = this.store.pipe(
      select(getVehicleFromRouterId),
      filter((vehicle) => !!vehicle),
      distinctUntilKeyChanged('id'),
      switchMap((vehicle) =>
        this.store.pipe(
          select(selectVehicleByIdWithHarnessAndHardware(vehicle.id)),
        ),
      ),
    );
    this.cameraInfo$ = this.store.pipe(select(getCurrentCameraInfoSelector));
    this.vehicleGroups$ = this.store.pipe(select(getAllVehicleGroups));
    this.vehicleStats$ = this.store.pipe(select(getVehicleStatsValues()));
    this.vehicleTypeDetail$ = this.store.pipe(select(getVehicleTypeDetail()));
    this.vehicleTypes$ = this.store.pipe(select(getUsedVehicleTypes));
    this.currentVehicleType$ = this.vehicle$.pipe(
      switchMap((vehicle) =>
        this.store.pipe(select(getUsedVehicleTypeById(vehicle?.type))),
      ),
    );
  }
}
