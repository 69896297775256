import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Employee, MomentService, PopoverService, Vehicle } from '@fc-core';
import { Moment } from 'moment';
import { select, Store } from '@ngrx/store';
import {
  addVehicleSchedule,
  deleteVehicleSchedule,
  loadVehicleSchedules,
  updateVehicleSchedule,
} from '@fc-store/actions/vehicle-schedule.actions';
import {
  FullSchedule,
  ScheduleInterface,
} from '@fc-core/models/operator-schedule.model';
import {
  getAllRawVehicles,
  getOperators,
  getVehicleFromRouterId,
} from '../../../store';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import {
  getAvailableOperators,
  getVehicleSchedule,
  getVehicleScheduleError,
  getVehicleScheduleLoading,
} from '@fc-store/selectors/vehicle-schedule.selectors';
import { MatDialog } from '@angular/material/dialog';
import { OperatorScheduleFormComponent } from '../../../employees/components/dialogs/operator-schedule-form/operator-schedule-form.component';
import { CalendarDay } from 'src/app/shared/ui/calendar/types';

@Component({
  selector: 'fc-vehicle-drivers-tab',
  template: `
    <div class="vehicle-drivers-container" *ngIf="vehicle$ | async">
      <div class="h2">Vehicles</div>

      <fc-calendar
        [itemType]="'driver'"
        [itemTemplate]="itemTemplate"
        [eventList]="eventList$ | async"
        [loading]="loading$ | async"
        [vehicleList]="vehicleList$ | async"
        [dayClickFunction]="dayClicked"
        [openEditFunction]="openEdit"
        (eventMoved)="updateEvent($event)"
        (editEvent)="openEdit($event)"
        (deleteEvent)="deleteEvent($event)"
        (currentCalendarMonthEvent)="setCurrentCalendarMonth($event)"
      ></fc-calendar>

      <ng-template #itemTemplate let-data>
        <ng-template
          *ngTemplateOutlet="
            operatorPhoto;
            context: { $implicit: data.employee }
          "
        ></ng-template>
        <div class="event-identifier">
          {{ data.employee?.firstName }} {{ data.employee?.lastName }}
        </div>
      </ng-template>

      <ng-template #operatorPhoto let-data>
        <div class="photo-container">
          <div class="event-photo">
            <fc-user-photo
              *ngIf="data?.avatar; else placeholder"
              [employeePhoto]="data?.avatar"
              [width]="20"
              [height]="20"
            ></fc-user-photo>
            <ng-template #placeholder>
              <fc-avatar-placeholder
                [size]="20"
                [color]="data?.color"
                [text]="data?.firstName[0] + data?.lastName[0]"
                [fontSize]="10"
              ></fc-avatar-placeholder>
            </ng-template>
          </div>
        </div>
      </ng-template>

      <ng-template #popoverContextTemplate let-data let-close="close">
        <fc-search-dropdown-panel
          [title]="'Operator search'"
          [selectedItems]=""
          [itemList]="todayOperatorList$ | async"
          [searchKey]="'fullName'"
          [additionalTemplate]="operatorPhoto"
          (itemSelected)="onDriverSelected(data.data, $event, close)"
          (enterPress)="onDriverSelected(data.data, $event, close)"
        ></fc-search-dropdown-panel>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .vehicle-drivers-container {
        padding: 24px;
      }

      .event-photo {
        margin-right: 4px;
      }

      .event-photo {
        width: 20px;
        height: 20px;
      }

      .event-identifier {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        margin-right: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    `,
  ],
})
export class VehicleDriversTabComponent implements OnInit {
  @ViewChild('popoverContextTemplate') popoverContextTemplate: TemplateRef<any>;

  vehicleList$: Observable<Vehicle[]>;
  operatorList$: Observable<Employee[]>;
  todayOperatorList$: Observable<Employee[]>;
  eventList$: Observable<FullSchedule[]>;
  loading$: Observable<boolean>;
  vehicle$: Observable<Vehicle>;
  currentVehicle: Vehicle;
  error$: Observable<any>;

  private currentCalendarMonth: Moment = this.moment.moment();

  constructor(
    private store: Store,
    private popover: PopoverService,
    private viewContainerRef: ViewContainerRef,
    private moment: MomentService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.todayOperatorList$ = this.store.pipe(select(getAvailableOperators));
    this.operatorList$ = this.store.pipe(select(getOperators));
    this.vehicleList$ = this.store.pipe(select(getAllRawVehicles));
    this.loading$ = this.store.pipe(select(getVehicleScheduleLoading));
    this.error$ = this.store.pipe(select(getVehicleScheduleError));
    this.eventList$ = combineLatest([
      this.store.pipe(select(getOperators)),
      this.store.pipe(select(getVehicleSchedule)),
    ]).pipe(
      map(([operators, schedule]) =>
        schedule.map(
          (item) =>
            ({
              ...item,
              vehicle: this.currentVehicle,
              operator: operators.find(
                (operator) => operator?.operator?.id === item?.operator,
              ),
            }) as FullSchedule,
        ),
      ),
    );

    this.vehicle$ = this.store.pipe(
      select(getVehicleFromRouterId),
      filter((vehicle) => !!vehicle),
      distinctUntilChanged((a, b) => a.id === b.id),
      map((vehicle) => {
        this.loadData(vehicle);
        this.currentVehicle = vehicle;
        return vehicle;
      }),
    );
  }

  private loadData(vehicle: Vehicle): void {
    this.store.dispatch(
      loadVehicleSchedules({
        options: {
          currentId: vehicle.id,
          lower: this.currentCalendarMonth.startOf('month').toISOString(),
          upper: this.currentCalendarMonth.endOf('month').toISOString(),
        },
      }),
    );
  }

  updateEvent(event: ScheduleInterface) {
    this.store.dispatch(
      updateVehicleSchedule({
        event: { ...event, vehicle: this.currentVehicle.id },
      }),
    );
  }

  openEdit = (event: FullSchedule) => {
    this.dialog
      .open(OperatorScheduleFormComponent, {
        data: {
          event: event
            ? {
                ...event,
                operator: event?.operator
                  ? { ...event.operator, id: event.operator.operator.id }
                  : null,
              }
            : null,
          itemsList$: this.operatorList$.pipe(
            map((items) =>
              items.map((item) => ({ ...item, id: item.operator.id })),
            ),
          ),
          loading$: this.loading$,
          delete: this.deleteEvent,
          searchKey: 'fullName',
        },
        panelClass: 'full-screen-modal',
        autoFocus: false,
        maxWidth: '100%',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: ScheduleInterface) => {
        if (!data) return;
        const payload: ScheduleInterface = {
          ...data,
          vehicle: this.currentVehicle.id,
        };
        data.id ? this.updateEvent(payload) : this.addEvent(payload);
      });
  };

  deleteEvent(event: FullSchedule) {
    if (!event) return;
    this.store.dispatch(
      deleteVehicleSchedule({
        event: {
          ...event,
          operator: event.operator.operator.id,
          vehicle: this.currentVehicle.id,
        },
      }),
    );
  }

  setCurrentCalendarMonth(month: Moment) {
    this.currentCalendarMonth = month.clone();
    this.loadData(this.currentVehicle);
  }

  dayClicked = (day: CalendarDay, clickTarget, openPosition) => {
    clickTarget.classList.add('active');
    this.popover
      .open({
        clickTarget,
        contextTemplate: this.popoverContextTemplate,
        viewContainerRef: this.viewContainerRef,
        data: {
          data: day,
        },
        overlayConfig: {
          openPosition,
          backdropClass: null,
        },
      })
      .pipe(take(1))
      .subscribe(() => {
        clickTarget.classList.remove('active');
      });
  };

  onDriverSelected(date: CalendarDay, employee: Employee, close: () => void) {
    const event = {
      vehicle: this.currentVehicle.id,
      operator: employee.operator.id,
      duration: {
        lower: date.date.clone().startOf('day').add(1, 'h').toISOString(),
        upper: date.date.clone().endOf('day').toISOString(),
      },
    };
    this.addEvent(event);
    close();
  }

  addEvent(event: ScheduleInterface) {
    const duration = event.duration.upper
      ? {
          lower: this.moment
            .moment(event.duration.lower)
            .startOf('day')
            .toISOString(),
          upper: this.moment
            .moment(event.duration.upper)
            .endOf('day')
            .toISOString(),
        }
      : {
          lower: this.moment
            .moment(event.duration.lower)
            .startOf('day')
            .toISOString(),
        };

    this.store.dispatch(
      addVehicleSchedule({
        event: {
          ...event,
          duration,
        },
      }),
    );
  }
}
