import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Hardware,
  HardwareApiService,
  Harness,
  HarnessApiService,
} from '@fc-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddDeviceInputComponent } from './add-device-input.component';

@Component({
  selector: 'fc-add-harness',
  template: `
    <div class="header">
      <h2 mat-dialog-title>Add Harness</h2>
      <div class="close">
        <fc-icon-button data-cy="vehicle-form-close" (click)="back.emit()">
          <fc-icon icon="icon-clear"></fc-icon>
        </fc-icon-button>
      </div>
    </div>
    <mat-dialog-content>
      <div class="add-device-container">
        <div class="add-device-content">
          <img
            class="add-device-image"
            src="assets/images/device-types/harness.png"
            alt="harness"
          />
          <div class="add-device-instruction">
            <div class="title h3">Simply connect your Tracker</div>
            <div class="text">
              Connect vehicle by adding Tracker Number and Harness below, we
              will automatically load other details. Use one of methods below:
            </div>
            <div class="instruction-list">
              <div class="instruction-item">
                <div class="number">1</div>
                <div class="instruction-text">Add tracker number manually</div>
              </div>
              <div class="instruction-item">
                <div class="number">2</div>
                <div class="instruction-text">
                  Scan Bar code using mobile phone
                </div>
              </div>
            </div>
          </div>
        </div>

        <form class="add-device-inputs">
          <fc-add-device-input
            #hardwareInput
            [requestFunction]="getHardwareList"
            [verifiedFunction]="verifyHardware"
            [serialLength]="9"
            [title]="'Tracker number'"
          ></fc-add-device-input>
          <fc-add-device-input
            #harnessInput
            [requestFunction]="getHarnessList"
            [verifiedFunction]="verifyHarness"
            [serialLength]="8"
            [title]="'Harness number'"
          ></fc-add-device-input>
        </form>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="space-between gap-8">
      <fc-text-button color="secondary" (click)="back.emit()"
        >back
      </fc-text-button>
      <fc-filled-button
        (click)="onAddDevice()"
        [disabled]="!valid"
        color="primary"
      >
        add
      </fc-filled-button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./add-device.scss'],
})
export class AddHarnessComponent implements AfterViewInit {
  @ViewChild('hardwareInput', { static: false })
  hardwareInput: AddDeviceInputComponent<Hardware>;
  @ViewChild('harnessInput', { static: false })
  harnessInput: AddDeviceInputComponent<Harness>;
  @Input() selectedHarness: { hardware: Hardware; harness: Harness };
  @Input() vehicleId: number;
  @Output() back = new EventEmitter();
  @Output() addDevice = new EventEmitter();

  constructor(
    private hardwareService: HardwareApiService,
    private harnessService: HarnessApiService,
  ) {}

  ngAfterViewInit() {
    if (this.selectedHarness?.harness && this.selectedHarness?.hardware) {
      this.hardwareInput?.itemsControl.setValue(this.selectedHarness.hardware);
      this.harnessInput?.itemsControl.setValue(this.selectedHarness.harness);
    }
  }

  get valid(): boolean {
    return (
      this.hardwareInput?.itemsControl.valid &&
      this.harnessInput?.itemsControl.valid
    );
  }

  onAddDevice() {
    this.addDevice.emit({
      hardware: this.hardwareInput?.itemsControl.value,
      harness: this.harnessInput?.itemsControl.value,
    });
  }

  getHardwareList = (serial: string): Observable<Hardware[]> => {
    return this.hardwareService
      .search(serial, false)
      .pipe(
        map((hardware) =>
          hardware.filter((item) => item.device.harnessRequired),
        ),
      );
  };

  getHarnessList = (serial: string): Observable<Harness[]> => {
    return this.harnessService.search(serial, false);
  };

  verifyHardware = (serial: string): Observable<Hardware> => {
    return this.hardwareService.validateHardware(serial, this.vehicleId);
  };

  verifyHarness = (serial: string): Observable<Harness> => {
    return this.harnessService.validateHarness(serial, this.vehicleId);
  };
}
