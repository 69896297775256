import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'fc-vehicle-photo',
  template: `
    <div class="vehicle-photo-container">
      <div class="vehicle-photo-header">
        <button
          (click)="$event.preventDefault()"
          class="menu-button"
          [matMenuTriggerFor]="menu"
        >
          <fc-icon icon="icon-more_vert" color="white" />
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="addNewPhoto.emit()" mat-menu-item>
            <fc-icon icon="icon-add_a_photo" />
            <span>Add photo</span>
          </button>
          <button (click)="photoDeleted.emit()" mat-menu-item>
            <fc-icon icon="icon-delete_outline" />
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
      <img
        *ngIf="photoSrc"
        class="vehicle-photo"
        [src]="photoSrc"
        alt="vehicle-photo"
      />
    </div>
  `,
  styles: [
    `
      @use 'var' as *;

      .vehicle-photo-container {
        position: relative;
        width: 100%;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .vehicle-photo-header {
          top: 0;
          left: 0;
          right: 0;
          position: absolute;
          display: none;
          height: 107px;
          background: linear-gradient(
            180deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 100%
          );

          @media (max-width: $sm-size) {
            display: flex;
          }

          .menu-button {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        &:hover {
          .vehicle-photo-header {
            display: flex;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclePhotoComponent {
  @Input() photoSrc: string;
  @Output() photoDeleted = new EventEmitter();
  @Output() addNewPhoto = new EventEmitter();
}
